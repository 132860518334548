const imgList = [
  {
    imageUrl: 'img/test1.jpg',
    href: 'www.baidu.com',
  },
  {
    imageUrl: 'img/test2.jpg',
  },
  {
    imageUrl: 'img/test3.jpg',
  },
];

export function getBannerTestApi() {
  return new Promise(resolve => setTimeout(() => resolve(imgList), 123));
}

export function getCourseTestApi() {
  return new Promise(resolve => setTimeout(() => resolve([
    {
      courseId: 1,
      courseName: `课程名称一${Date.now()}`,
      classification: '图文课程',
    },
    {
      courseId: 2,
      courseName: '课程名称二课程名称二课程名称二课程名称二',
      classification: '视频课程',
    },
    {
      courseId: 3,
      courseName: '课程名称三课程名称三课程名称三课程名称三',
      classification: '视频课程',
    },
    {
      courseId: 4,
      courseName: '课程名称四',
      classification: '图文课程',
    },
  ]), 123));
}

