var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{ref:"pageContainer",staticClass:"home-course-container",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-pull-refresh',{staticStyle:{"position":"relative","z-index":"1"},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.pagination.pageNum < 2 ? '' : '加载中...')+" ")]},proxy:true}]),model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('div',{staticClass:"home-course-swiper-wrap"},[_c('div',{staticClass:"swiper-container"},[_c('van-swipe',{staticStyle:{"height":"100%"},attrs:{"duration":"1000","autoplay":"5000"}},_vm._l((_vm.imageList),function(ref,index){
var imageUrl = ref.imageUrl;
var href = ref.href;
return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.handleAdClick(href)}}},[_c('van-image',{attrs:{"src":imageUrl,"fill":"cover"}})],1)}),1)],1)]),_c('van-sticky',{attrs:{"container":_vm.container}},[_c('div',{staticClass:"search-tabs-container"},_vm._l((_vm.COURSE_CONTENT_TYPE_LIST),function(ref){
var id = ref.id;
var name = ref.name;
return _c('div',{key:id,staticClass:"search-tab",class:{ checked: id === _vm.searchForm.type },on:{"click":function($event){return _vm.handleOnSearch(id)}}},[_c('span',[_vm._v(_vm._s(name))])])}),0)]),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(course){return _c('list-item',{key:course.courseId,attrs:{"value":course,"props":{
            title: 'courseName',
            imgSrc: 'coverImg',
            tagName: 'classification',
          }},scopedSlots:_vm._u([{key:"tag-name",fn:function(ref){
          var data = ref.data;
return [_vm._v(_vm._s(_vm.classificationMap[data]))]}},{key:"bottom",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"course-btn-container"},[_c('van-button',{attrs:{"round":"","type":"info","size":"mini","plain":""},on:{"click":function($event){return _vm.toCourse(course)}}},[_vm._v("进入课程")]),(_vm.isShowToSubjectBtn(course))?_c('van-button',{attrs:{"round":"","type":"info","size":"mini"},on:{"click":function($event){return _vm.toSubject(course)}}},[_vm._v(" 进入评测 ")]):_vm._e(),(_vm.getIsPassText(course))?_c('van-button',{attrs:{"round":"","type":"info","size":"mini","plain":""},on:{"click":function($event){return _vm.viewSubjectResult(course)}}},[_vm._v(" "+_vm._s(_vm.getIsPassText(course))+" ")]):_vm._e()],1)]}}],null,true)})}),1)],1),_c('van-overlay',{attrs:{"z-index":"9","show":_vm.examResult.show}},[_c('div',{staticClass:"exam-result-warp"},[_c('div',{staticClass:"exam-result-container"},[_c('div',{staticClass:"result-status",class:{ isPass: _vm.examResult.isPass }}),(_vm.examResult.isPass)?_c('p',{staticClass:"result-title"},[_vm._v("恭喜您"),_c('br'),_vm._v("通过此次测评")]):_c('p',{staticClass:"result-title"},[_vm._v("很遗憾"),_c('br'),_vm._v("您没有通过此次测评")]),_c('div',{staticClass:"exam-result-item"},[_c('label',[_vm._v("题目数量：")]),_c('span',[_vm._v(_vm._s(_vm.examResult.total)+"题")])]),_c('div',{staticClass:"exam-result-item"},[_c('label',[_vm._v("正确数量：")]),_c('span',[_vm._v(_vm._s(_vm.examResult.rightTotal)+"题")])]),_c('div',{staticClass:"exam-result-item"},[_c('label',[_vm._v("正确率：")]),_c('span',[_vm._v(_vm._s(_vm.examResult.rightRate)+"%")])]),_c('van-button',{attrs:{"round":"","type":"primary"},on:{"click":function($event){_vm.examResult.show = false}}},[_vm._v("确 定")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }