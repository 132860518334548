<template>
  <page-container
    ref="pageContainer"
    class="home-course-container"
    :scroll-top.sync="scrollTop"
  >
    <!-- 下拉刷新 -->
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      style="position: relative;z-index: 1"
    >
      <template #loading>
        {{ pagination.pageNum < 2 ? '' : '加载中...' }}
      </template>

      <!-- 广告 -->
      <div class="home-course-swiper-wrap">
        <div class="swiper-container">
          <van-swipe
            style="height: 100%"
            duration="1000"
            autoplay="5000"
          >
            <van-swipe-item
              v-for="({ imageUrl, href }, index) of imageList"
              :key="index"
              @click="handleAdClick(href)"
            >
              <van-image
                :src="imageUrl"
                fill="cover"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <van-sticky
        :container="container"
      >
        <!-- 课程分类搜索 -->
        <div class="search-tabs-container">
          <div
            v-for="{ id, name } in COURSE_CONTENT_TYPE_LIST"
            :key="id"
            class="search-tab"
            :class="{ checked: id === searchForm.type }"
            @click="handleOnSearch(id)"
          >
            <span>{{ name }}</span>
          </div>
        </div>
      </van-sticky>

      <!-- 更多列表加载 -->
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <!-- 课程 -->
        <list-item
          v-for="course of dataList"
          :key="course.courseId"
          :value="course"
          :props="{
              title: 'courseName',
              imgSrc: 'coverImg',
              tagName: 'classification',
            }"
        >
          <template #tag-name="{ data }">{{ classificationMap[data] }}</template>
          <template #bottom="{ data }">
            <div class="course-btn-container">
              <van-button round type="info" size="mini" plain @click="toCourse(course)">进入课程</van-button>
              <van-button
                v-if="isShowToSubjectBtn(course)"
                round type="info" size="mini"
                @click="toSubject(course)"
              >
                进入评测
              </van-button>
              <!-- 查看测评结果 -->
              <van-button
                v-if="getIsPassText(course)"
                round type="info" size="mini" plain
                @click="viewSubjectResult(course)"
              >
                {{ getIsPassText(course) }}
              </van-button>
            </div>
          </template>
        </list-item>
      </van-list>
    </van-pull-refresh>

    <van-overlay z-index="9" :show="examResult.show">
      <div class="exam-result-warp">
        <div class="exam-result-container">
          <div
            class="result-status"
            :class="{ isPass: examResult.isPass }"
          />
          <p v-if="examResult.isPass" class="result-title">恭喜您<br>通过此次测评</p>
          <p v-else class="result-title">很遗憾<br>您没有通过此次测评</p>
          <div class="exam-result-item">
            <label>题目数量：</label>
            <span>{{ examResult.total }}题</span>
          </div>
          <div class="exam-result-item">
            <label>正确数量：</label>
            <span>{{ examResult.rightTotal }}题</span>
          </div>
          <div class="exam-result-item">
            <label>正确率：</label>
            <span>{{ examResult.rightRate }}%</span>
          </div>
          <van-button round type="primary" @click="examResult.show = false">确 定</van-button>
        </div>
      </div>
    </van-overlay>


  </page-container>
</template>

<script>
import { mapState } from 'vuex';

import { COURSE_CONTENT_TYPE, COURSE_CONTENT_TYPE_LIST } from '@/views/course/const';
import ListItem from '@/components/listItem';
import { getAdCarouselList, getDictType } from '@/api/system';
import { getCoursePageList } from '@/api/course';
import { getBannerTestApi } from '@/test/home';
import { getCourseListTestApi } from '@/test/course';

export default {
  name: 'course',
  components: { ListItem },
  data() {
    return {
      container: null,
      scrollTop: 0,

      classificationMap: {},

      imageList: [],

      searchForm: {
        type: COURSE_CONTENT_TYPE.RICH,
      },

      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      examResultShow: false,
      examResult: {
        show: false,
        isPass: '',
        total: '',
        rightTotal: '',
        rightRate: '',
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeCourse.refreshing,
    }),
    COURSE_CONTENT_TYPE_LIST() {
      return COURSE_CONTENT_TYPE_LIST;
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeCourse/setRefreshing', false);

      Object.assign(this, {
        searchForm: {
          type: COURSE_CONTENT_TYPE.RICH,
        },
        dataList: [],
        pagination: {
          // 分页参数
          pageNum: 0,
          pageSize: 10,
          total: 0,
        },
      });
      this.onRefresh();
    }, { immediate: true });
  },
  mounted() {
    this.container = this.$refs.pageContainer;
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },

  methods: {
    handleAdClick(href) {
      if (href)
        window.location.href = href;
      // href && window.open(href);
    },
    handleOnSearch(type) {
      this.searchForm.type = type;
      this.onRefresh();
    },
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getCoursePageList({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total)
          this.finished = true;
      });
    },
    onRefresh() {
      // 字典
      getDictType('classification').then(res => {
        let dicList = Array.isArray(res) ? res : [];
        this.classificationMap = [{}, ...dicList].reduce((map, { value, label }) => {
          map[value] = label;
          return map;
        });
      });

      getAdCarouselList().then(res => {
        this.imageList = Array.isArray(res) ? res : [];
      });

      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    getIsPassText(course) {
      let { isAccess } = course;
      return {
        '1': '已通过测评',
      }[isAccess]
    },
    isShowToSubjectBtn(course) {
      let { evalId, isAccess } = course;
      return isAccess !== '1' && evalId;
    },
    viewSubjectResult(course) {
      let { isAccess, num, quesNum } = course;
      Object.assign(this.examResult, {
        show: true,
        isPass: isAccess === '1',
        total: quesNum,
        rightTotal: num,
        rightRate: Math.round((num / quesNum) * 100),
      })
    },
    toSubject(course) {
      this.$router.push({
        path: '/subjectDetail',
        query: { courseId: course.courseId },
      });
    },
    toCourse(course) {
      this.$router.push({
        path: '/courseDetail',
        query: { courseId: course.courseId, },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home-course-container {

  .home-course-swiper-wrap {
    position: relative;
    width: 100%;
    margin: 0 0 13px 0;
    padding-top: 54%;
    background-color: #fff;

    @swipe-padding: 15px;

    .swiper-container {
      position: absolute;
      z-index: 1;
      top: @swipe-padding;
      bottom: @swipe-padding;
      left: 0;
      width: 100%;

      .van-swipe-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 0 @swipe-padding;

        .van-image {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
        }
      }

      /deep/ .van-swipe__indicator--active {
        bottom: 7px;
        border-radius: 4px;
        transform: scale(1.2);
        transition: all .2s;
      }
    }
  }

  .search-tabs-container {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    height: 47px;
    background-color: #fff;

    .search-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      padding: 0 5px;

      & > span {
        position: relative;
        z-index: 2;
        letter-spacing: 1px;
        font-size: 17px;
        color: #888;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 15px;
        width: 0;
        height: 0;
        border-radius: 0;
        background: rgba(255, 255, 255, 0);
        transition: all .2s;
      }

      &.checked {
        & > span {
          color: #333;
          font-weight: bolder;
        }

        &:after {
          width: 100%;
          height: 5px;
          border-radius: 3px;
          background: #65d27e;
          background: -webkit-linear-gradient(to right, #65d27e, #93e8a5, #c1ffcb);
          background: linear-gradient(to right, #65d27e, #93e8a5, #c1ffcb);
        }
      }
    }
  }

  @padding: 16px;

  .list-item {
    padding-right: @padding;
    padding-left: @padding;

    &:nth-of-type(1) {
      padding-top: 8px;
    }

    & + .list-item {
      position: relative;
      z-index: 1;
      border: none;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: @padding;
        left: @padding;
        border-top: 1px solid #dedede;

      }
    }

    .course-btn-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 12px 0 0 0;
      text-align: right;

      .van-button {
        padding: 0 14px;

        & + .van-button {
          margin-left: 14px;
        }
      }

      .pass-subject-text {
        margin-left: 14px;
        color: #3cc754;
      }
    }
  }

  .exam-result-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .exam-result-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px 0 25px 0;
    width: 66.66666666%;
    background-color: #fff;
    border-radius: 8px;

    .result-status {
      margin: 0 0 13px 0;
      width: 60px;
      height: 47px;
      background: url("../../assets/subject/no-pass.svg") center no-repeat;
      background-size: 100% auto;

      &.isPass {
        background: url("../../assets/subject/isPass.svg") center no-repeat;
        background-size: 100% auto;
      }
    }

    .result-title {
      margin: 0 0 29px 0;
      line-height: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: bolder;
      color: #333;
    }

    .exam-result-item {
      display: flex;
      width: 100%;
      line-height: 1;
      letter-spacing: 1px;
      font-size: 16px;
      color: #666;

      & > label {
        width: 55.8%;
        text-align: right;
      }

      & > span {
        width: 44.2%;
        text-align: left;
        letter-spacing: 2px;
      }

      & + .exam-result-item {
        margin: 14px 0 0 0;
      }
    }

    .van-button {
      margin: 25px 0 0 0;
      padding: 0 43px;
      height: 36px;
    }
  }
}
</style>
