// 课程内容类型（1：视频   2：图文）
export const COURSE_CONTENT_TYPE = {
  VIDEO: '1',
  RICH: '2',
};

const { VIDEO, RICH } = COURSE_CONTENT_TYPE;
export const COURSE_CONTENT_TYPE_LIST = [
  { id: RICH, name: '图文课程' },
  { id: VIDEO, name: '视频课程' },
];
