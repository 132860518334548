import request from '@/plugins/axios';

/**
 * 课程分页查询
 */
export function getCoursePageList(params) {
  return request({
    url: `school-mobile/hCourseInfo/page`,
    method: 'get',
    params
  });
}

/**
 * 课程详情
 * /hCourseInfo/{}
 */
export function getCourseInfo(params) {
  return request({
    url: `school-mobile/hCourseInfo/${params.courseId}`,
    method: 'get',
    params
  });
}

/**
 * 完成课程
 */
export function finishCourse(params) {
  return request({
    url: `school-mobile/hCourseInfo/finish`,
    method: 'get',
    params
  });
}
